import ROUTE_NAMES from "@/core/route/util/routeNames"
import { isDiscoDomain } from "@/core/route/util/routeUtils"
import { stopImpersonatingTestUser } from "@/product/util/hook/useInitImpersonateTestUser"
import { isE2ETest } from "@utils/e2e"
import { getDevice, isWebView } from "@utils/webView/webViewUtils"
import axios from "axios"
import { unstable_batchedUpdates as batchedUpdates } from "react-dom"
import { Environment as ReactRelayEnvironment } from "react-relay"
import ConnectionHandler from "relay-connection-handler-plus"
import {
  Environment as RelayRuntimeEnvironment,
  FetchFunction as RelayFetchFunction,
  GraphQLResponse,
  Network,
  RecordSource,
  Store,
  TaskScheduler,
} from "relay-runtime"
import relayDefaultHandlerProvider from "relay-runtime/lib/handlers/RelayDefaultHandlerProvider"
import { v4 as uuidv4 } from "uuid"

const SESSION_EXPIRED_ERROR = "Context creation failed: Invalid session cookie"
export const INVALID_USER_LINK_KEY_ERROR = "Invalid user link."

/** Execute a fetch request with the provided query and variables. */
const fetchRelay: RelayFetchFunction = async (params, variables) => {
  const requestID = uuidv4().toString()
  const endpoint = isDiscoDomain(window.location.host) ? GRAPHQL_URL : "/graphql"

  const headers: Record<string, string> = {
    "X-Disco-Request-ID": requestID,
    "X-Disco-Platform": isWebView() ? "mobile" : "web",
    "X-Disco-Device": getDevice(),
  }

  if (isE2ETest()) {
    headers["X-E2E-Test"] = "1"
  }
  if (isWebView()) {
    headers["X-WebView"] = "1"
  }
  if (window.discoViewAs) {
    headers["X-Disco-View-As"] = window.discoViewAs
  }
  if (window.discoUlKey) {
    headers["X-UL-Key"] = window.discoUlKey
  }

  try {
    const res = await axios.post<GraphQLResponse>(
      `${endpoint}?requestID=${requestID}`,
      {
        query: params.text,
        variables,
      },
      {
        // Include cookies in graphql requests
        withCredentials: true,
        headers,
      }
    )
    return res.data
  } catch (error: any) {
    const errorMessage = error?.response?.data?.errors?.[0]?.message

    if (errorMessage === SESSION_EXPIRED_ERROR) {
      // Handle session expiry gracefully by redirecting to login
      window.location.href = ROUTE_NAMES.AUTHENTICATION.LOGIN
    } else if (errorMessage === INVALID_USER_LINK_KEY_ERROR) {
      stopImpersonatingTestUser()
    }
    throw error
  }
}

// Use React's batchedUpdates to ensure that the parent component will update first before a child component.
// Reference: https://github.com/facebook/relay/issues/3514
const RelayScheduler: TaskScheduler = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cancel: () => {},
  schedule: (task) => {
    batchedUpdates(task)
    return ""
  },
}

/** Singleton instance of Relay Environment with a cache */
const RelayEnvironment = new RelayRuntimeEnvironment({
  network: Network.create(fetchRelay),
  store: new Store(new RecordSource()),
  handlerProvider: (handle) => {
    switch (handle) {
      case "connection":
        return ConnectionHandler
      default:
        return relayDefaultHandlerProvider(handle)
    }
  },
  scheduler: RelayScheduler,
  // Type hack to make the environment compatible with react-relay and relay-runtime
  // without the 12.0.0 version of @types/react-relay available.
}) as RelayRuntimeEnvironment & ReactRelayEnvironment

export default RelayEnvironment
